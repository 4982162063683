class User{
    constructor(id, nombre, apellido, cuil, email, whatsapp, tipo, password = ''){
        this.id = id;
        this.nombre = nombre;
        this.apellido = apellido;
        this.cuil = cuil;
        this.email = email;
        this.whatsapp = whatsapp;
        this.tipo = tipo;
        this.password = password
    }
}
export default User;