<template>
  <div>
    <CardForm :titulo="titleCard" @enviarForm="guardar" :button="true">
      <float-label
        label="Nombre:"
        clase="col-sm-6 col-12"
        id="user"
        placeholder="Nombre"
        type="text"
        classInput="form-control"
        v-model="user.nombre"
        :required="enviado && !$v.user.nombre.required"
      >
      </float-label>
      <float-label
        label="Apellido:"
        clase="col-sm-6 col-12"
        id="apellido"
        placeholder="Apellido"
        type="text"
        classInput="form-control"
        v-model="user.apellido"
        :required="enviado && !$v.user.apellido.required"
      >
      </float-label>
      <float-label
        label="CUIL:"
        clase="col-sm-6 col-12"
        id="cuil"
        placeholder="CUIL"
        type="text"
        inputmode="numeric"
        classInput="form-control"
        v-model="user.cuil"
        :required="enviado && !$v.user.cuil.required"
        :numeric="enviado && !$v.user.cuil.numeric"
      >
      </float-label>
      <float-label
        label="Email:"
        clase="col-sm-6 col-12"
        id="email"
        placeholder="Email"
        type="email"
        classInput="form-control"
        v-model="user.email"
        :required="enviado && !$v.user.email.required"
      >
      </float-label>
      <float-label
        label="Whatsapp:"
        clase="col-sm-6 col-12"
        id="whatsapp"
        placeholder="Whatsapp"
        type="text"
        classInput="form-control"
        v-model="user.whatsapp"
      >
      </float-label>
    </CardForm>
  </div>
</template>
<script>
import FloatLabel from "@/components/layouts/FloatLabel";
import CardForm from "@/components/layouts/CardForm";
import User from "@/models/user";
import { required, numeric } from "vuelidate/lib/validators";
import Axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "NuevoUsuario",
  components: {
    FloatLabel,
    CardForm,
  },
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
  validations: {
    user: {
      nombre: { required },
      apellido: { required },
      cuil: { required, numeric },
      email: { required },
    },
  },
  data() {
    return {
      enviado: false,
      user: new User(null, null, null, null, null, null, "user"),
    };
  },
  methods: {
    guardar() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.user.password = this.user.cuil;
      Axios.post("/user", this.user)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: `${res.data.user.nombre} se guardó con éxito.`,
            });
            this.$router.push({ name: "ListarAdministradores" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `No se pudo editar`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${res.data.user.nombre} no se guardó.`,
          });
          console.log(err);
        });
    },
  },
};
</script>
