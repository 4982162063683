<template>
  <div :class="'form-group input-group ' + clase">
    <div v-if="prepend" class="input-group-prepend">
      <span class="input-group-text">{{ prepend }}</span>
    </div>
    <label class="has-float-label">
      <template v-if="select">
        <select
          :id="id"
          :placeholder="placeholder"
          :value="value"
          @input="update($event.target.value)"
          :class="[classInput ? classInput : 'form-control', (required || numeric) ? 'is-invalid': '']"
        >
          <template v-if="options">
            <option :value="null">Seleccione una opción</option>
            <option v-for="option in options" :key="option" :value="option">
              {{ option }}
            </option>
          </template>
          <template v-else>
            <slot name="options"></slot>
          </template>
        </select>
      </template>
      <template v-else-if="textarea">
        <textarea
          :id="id"
          :placeholder="placeholder"
          :value="value"
          @input="update($event.target.value)"
          :class="[classInput ? classInput : 'form-control', (required || numeric) ? 'is-invalid': '']"
        ></textarea>
      </template>
      <template v-else>
        <input
          :id="id"
          :placeholder="placeholder"
          :type="type ? type : 'text'"
          :inputmode="inputmode"
          :value="value"
          @input="update($event.target.value)"
          :class="[classInput ? classInput : 'form-control', (required || numeric) ? 'is-invalid': '']"
        />
      </template>
      <span>{{ label }}</span>
    </label>
    <div v-if="append" class="input-group-append">
      <span class="input-group-text">{{ append }}</span>
    </div>
    <div class="invalid-feedback" v-if="required">
      Éste campo es obligatorio.
    </div>
    <div class="invalid-feedback" v-if="numeric">Sólo se aceptan números.</div>
    <slot name="validation"></slot>
  </div>
</template>
<script>
export default {
  name: "FloatLabel",
  props: {
    label: {
      type: String,
      required: true,
    },
    clase: {
      type: String,
      required: true,
    },
    prepend: String,
    append: String,
    required: false,
    numeric: false,
    id: {
      type: String,
      required: true,
    },
    type: String,
    classInput: String,
    placeholder: {
      type: String,
      required: true,
    },
    inputmode: String,
    select: false,
    options: Array,
    textarea: false,
    value: null,
  },
  methods: {
    update: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>