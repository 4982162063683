<template>
  <form @submit.prevent="enviarForm">
    <div class="mb-3 card-border card">
      <div class="card-header">
        {{ titulo }}
      </div>
      <div class="card-body">
        <div class="row">
          <slot></slot>
        </div>
      </div>
      <div class="d-block text-right card-footer" v-if="button">
        <button class="mt-1 btn btn-primary" type="submit">Guardar</button>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: "CardForm",
  props:{
    titulo:String,
    button: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    enviarForm() {
      this.$emit("enviarForm");
    },
  }
};
</script>